<template>
    <div class="modal-remove">
        <div class="remove-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="remove-content">
            <div class="content-title">REMOVE MESSAGE</div>
            <div class="content-info">
                You are about to remove a message from <span v-html="modalsData.message.user.username" class="info-username" v-bind:class="[ 'username-' + modalsData.message.user.rank ]"></span>!
            </div>
            <div class="content-message">
                <div class="message-text" v-html="modalsData.message.message"></div>
            </div>
            <div class="content-button">
                <button v-on:click="modalRemoveButton()" class="button-remove" v-bind:disabled="socketSendLoading !== null">
                    <transition name="fade" mode="out-in">
                        <div class="button-loading" v-if="socketSendLoading === 'ChatRemove'" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div class="button-content" v-else key="content">REMOVE MESSAGE</div>
                    </transition>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'ModalRemove',
        components: {
            LoadingAnimation
        },
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'chatSendRemoveSocket'
            ]),
            modalRemoveButton() {
                this.chatSendRemoveSocket({ messageId: this.modalsData.message._id, room: this.modalsData.message.room });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'modalsData'
            ])
        }
    }
</script>

<style scoped>
    .modal-remove {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0 44px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-remove .remove-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-remove .remove-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-remove .remove-close button:hover {
        color: #ffffff;
    }

    .modal-remove .remove-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-remove .remove-close button:hover svg {
        fill: #ffffff;
    }

    .modal-remove .remove-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-remove .content-title {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800;
        color: #323743;
    }

    .modal-remove .content-info {
        display: flex;
        align-items: center;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-remove .content-info span.info-username {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 700;
    }

    .modal-remove .content-info span.info-username {
        color: #bbbbbb;
    }

    .modal-remove .content-info span.info-username.username-mod {
        color: #00c74d;
    }

    .modal-remove .content-info span.info-username.username-admin {
        color: #fd3b31;
    }

    .modal-remove .content-message {
        width: 370px;
        margin-top: 25px;
    }

    .modal-remove .message-text {
        margin-top: 8px;
        padding: 8px 21px;
        border-radius: 8px;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        background: #212732;
    }

    .modal-remove .content-button {
        width: 370px;
        margin-top: 18px;
    }

    .modal-remove .content-button button.button-remove {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-remove .content-button button.button-remove:hover {
        background: #00de56;
    }

    .modal-remove .content-button button.button-remove.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .modal-remove .content-button button.button-remove .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-remove .content-button button.button-remove .button-loading svg {
        font-size: 16px;
    }

    .modal-remove .content-button button.button-remove .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .modal-remove .content-button button.button-remove .button-loading.fade-leave-to {
        opacity: 0;
    }

    .modal-remove .content-button button.button-remove .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .modal-remove .content-button button.button-remove .button-content.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 650px) {

        .modal-remove {
            width: 100%;
        }

    }

    @media only screen and (max-width: 450px) {

        .modal-remove .content-button {
            width: 100%;
        }

    }
</style>
