<template>
    <div class="modal-ban">
        <div class="ban-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="ban-content">
            <div class="content-title">BAN USER</div>
            <div class="content-info">
                <div class="info-user">You are about to ban <span v-html="modalsData.user.username" class="user-username" v-bind:class="[ 'username-' + modalsData.user.rank ]"></span>!</div>
            </div>
            <div class="content-reason">
                <IconInfo />
                <select v-model="modalReason">
                    <option value="cheating">Cheating</option>
                    <option value="scamming">Scamming</option>
                    <option value="self request">Self request</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div class="content-time">
                <IconClock />
                <select v-model="modalTime">
                    <option value="none">None</option>
                    <option value="1h">1 hour</option>
                    <option value="24h">24 hours</option>
                    <option value="7d">7 days</option>
                    <option value="14d">14 days</option>
                    <option value="30d">30 days</option>
                    <option value="permanent">Permanent</option>
                </select>
                <AvatarImage v-bind:image="modalsData.user.avatar" />
            </div>
            <div class="content-button">
                <button v-on:click="modalBanButton()" class="button-mute" v-bind:disabled="socketSendLoading !== null">
                    <transition name="fade" mode="out-in">
                        <div class="button-loading" v-if="socketSendLoading === 'AdminUserBan'" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div class="button-content" v-else key="content">BAN USER</div>
                    </transition>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconInfo from '@/components/icons/IconInfo';
    import IconClock from '@/components/icons/IconClock';
    import AvatarImage from '@/components/AvatarImage';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'ModalBan',
        components: {
            IconInfo,
            IconClock,
            AvatarImage,
            LoadingAnimation
        },
        data() {
            return {
                modalReason: 'cheating',
                modalTime: '1h'
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'notificationShow', 
                'adminSendUserBanSocket'
            ]),
            modalBanButton() {
                if(this.modalReason === null) {
                    this.notificationShow({type: 'error', message: 'Your selected ban reason is invalid.'});
                    return;
                }

                if(this.modalTime === null) {
                    this.notificationShow({type: 'error', message: 'Your entered ban time is invalid.'});
                    return;
                }

                this.adminSendUserBanSocket({ userId: this.modalsData.user._id, reason: this.modalReason, time: this.modalTime });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalTimeDiff', 
                'modalsData'
            ])
        }
    }
</script>

<style scoped>
    .modal-ban {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0 44px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-ban .ban-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-ban .ban-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-ban .ban-close button:hover {
        color: #ffffff;
    }

    .modal-ban .ban-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-ban .ban-close button:hover svg {
        fill: #ffffff;
    }

    .modal-ban .ban-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-ban .content-title {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800;
        color: #323743;
    }

    .modal-ban .content-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7px;
    }

    .modal-ban .info-user {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-ban .info-user span.user-username {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 700;
    }

    .modal-ban .info-user span.user-username {
        color: #bbbbbb;
    }

    .modal-ban .info-user span.user-username.username-mod {
        color: #00c74d;
    }

    .modal-ban .info-user span.user-username.username-admin {
        color: #fd3b31;
    }

    .modal-ban .content-reason,
    .modal-ban .content-time {
        width: 370px;
        position: relative;
        margin-top: 25px;
    }

    .modal-ban .content-time {
        margin-top: 10px;
    }

    .modal-ban .content-reason select,
    .modal-ban .content-time select {
        width: 100%;
        height: 54px;
        padding: 0 60px 0 50px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .modal-ban .content-reason select::placeholder,
    .modal-ban .content-time select::placeholder {
        color: #596076;
    }

    .modal-ban .content-reason svg,
    .modal-ban .content-time svg {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translate(0, -50%);
        fill: #fd3b31;
    }

    .modal-ban .content-time .avatar-image {
        width: 33px;
        height: 33px;
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translate(0, -50%);
        border-radius: 5px;
    }

    .modal-ban .content-button {
        width: 370px;
        margin-top: 18px;
    }

    .modal-ban .content-button button.button-mute {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-ban .content-button button.button-mute:hover {
        background: #00de56;
    }

    .modal-ban .content-button button.button-mute.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .modal-ban .content-button button.button-mute .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-ban .content-button button.button-mute .button-loading svg {
        font-size: 16px;
    }

    .modal-ban .content-button button.button-mute .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .modal-ban .content-button button.button-mute .button-loading.fade-leave-to {
        opacity: 0;
    }

    .modal-ban .content-button button.button-mute .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .modal-ban .content-button button.button-mute .button-content.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 650px) {

        .modal-ban {
            width: 100%;
        }

    }

    @media only screen and (max-width: 450px) {

        .modal-ban .ban-content {
            padding: 0 20px;
        }

        .modal-ban .content-input {
            width: 100%;
        }

        .modal-ban .content-button {
            width: 100%;
        }

    }
</style>
