import axios from 'axios';

const state = {
    authToken: {
        token: localStorage.getItem('token') === null ? null : localStorage.getItem('token'),
        loading: false
    },
    authUser: {
        user: null,
        loading: false
    }
}

const getters = {
    authToken: state => state.authToken,
    authUser: state => state.authUser
}

const mutations = {
    auth_set_token(state, token) {
        state.authToken.token = token;
    },
    auth_set_token_loading(state, status) {
        state.authToken.loading = status;
    },
    auth_set_user(state, user) {
        state.authUser.user = user;
    },
    auth_update_user(state, user) {
        state.authUser.user.balance = user.balance;
        state.authUser.user.xp = user.xp;
        state.authUser.user.stats = user.stats;
        state.authUser.user.rakeback = user.rakeback;
        state.authUser.user.mute = user.mute;
        state.authUser.user.ban = user.ban;
        state.authUser.user.updatedAt = user.updatedAt;
    },
    auth_set_user_loading(state, status) {
        state.authUser.loading = status;
    },
    auth_set_user_agree(state) {
        state.authUser.user.agreed = true;
    },
    auth_set_user_balance(state, balance) {
        state.authUser.user.balance = balance;
    },
    auth_set_user_stats(state, stats) {
        state.authUser.user.stats = stats;
    },
    auth_set_user_rakeback(state, rakeback) {
        state.authUser.user.rakeback = rakeback;
    },
    auth_set_user_updated_at(state, updatedAt) {
        state.authUser.user.updatedAt = updatedAt;
    },
    auth_set_user_trade_link(state, tradeLink) {
        state.authUser.user.tradeLink = tradeLink;
    },
    auth_set_user_anonymous(state, anonymous) {
        state.authUser.user.anonymous = anonymous;
    },
    auth_set_user_client_seed(state, clientSeed) {
        state.authUser.user.fair.clientSeed = clientSeed;
    }
}

const actions = {
    async authExchangeToken({ getters, commit, dispatch }, data) {
        if(getters.authToken.loading === true) { return; }
        commit('auth_set_token_loading', true);

        try {
            const res = await axios.post('/api/auth/exchange', data);
            if(res.data.success) {
                if(res.data.user !== undefined) {
                    commit('auth_set_user', res.data.user);
                } else {
                    localStorage.setItem('token', res.data.token);
                    axios.defaults.headers.common['x-auth-token'] = res.data.token;

                    commit('auth_set_token', res.data.token);

                    dispatch('authGetUser', {});
                    setTimeout(() => { dispatch('socketConnectClient'); }, 1000);
                }
            }
        } catch(err) {
            if(err.response !== undefined && err.response !== null) {
                dispatch('notificationShow', err.response.data.error);
            }
        }

        commit('auth_set_token_loading', false);
    },
    async authGetUser({ getters, commit, dispatch }, data) {
        if(getters.authUser.loading === true) { return; }
        commit('auth_set_user_loading', true);

        try {
            const res = await axios.get('/api/auth/me', data);
            if(res.data.success) {
                commit('auth_set_user', res.data.user);

                const refCode = localStorage.getItem('ref-code');
                if(refCode !== null) {
                    localStorage.removeItem('ref-code');
                    dispatch('modalSetData', { modal: 'Redeem', data: { code: refCode } });
                    dispatch('modalsSetShow', 'Redeem');
                }

                if(res.data.user.agreed === undefined || res.data.user.agreed === false) {
                    dispatch('modalsSetShow', 'Agree');
                }

                window.$crisp.push(['set', 'user:nickname', [res.data.user.username]]);
                window.$crisp.push(['set', 'user:avatar', [res.data.user.avatar]]);
            }
        } catch(err) {
            if(err.response !== undefined && err.response !== null) {
                if(err.response.status === 401) { dispatch('authLogoutUser'); }
                dispatch('notificationShow', err.response.data.error);
            }
        }

        commit('auth_set_user_loading', false);
    },
    authLogoutUser() {
        delete axios.defaults.headers.common['x-auth-token'];
        localStorage.removeItem('token');
        location.reload();
    }
}

const auth = {
    state,
    mutations,
    actions,
    getters
}

export default auth;
