import store from '../store';

import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home';
import Blackjack from '../views/Blackjack';
import Mines from '../views/Mines';
import Towers from '../views/Towers';
import Upgrader from '../views/Upgrader';
import Roulette from '../views/Roulette';
import Cases from '../views/cases/Cases';
import CasesOverview from '../views/cases/CasesOverview';
import CasesBox from '../views/cases/CasesBox';
import Battles from '../views/battles/Battles';
import BattlesOverview from '../views/battles/BattlesOverview';
import BattlesCreate from '../views/battles/BattlesCreate';
import BattlesGame from '../views/battles/BattlesGame';
import Deposit from '../views/deposit/Deposit';
import DepositRust from '../views/deposit/DepositRust';
import DepositCrypto from '../views/deposit/DepositCrypto';
import DepositGift from '../views/deposit/DepositGift';
import DepositCredit from '../views/deposit/DepositCredit';
import Withdraw from '../views/withdraw/Withdraw';
import WithdrawRust from '../views/withdraw/WithdrawRust';
import Profile from '../views/profile/Profile';
import ProfileOverview from '../views/profile/ProfileOverview';
import ProfileSettings from '../views/profile/ProfileSettings';
import ProfileBets from '../views/profile/ProfileBets';
import ProfileTransactions from '../views/profile/ProfileTransactions';
import Leaderboard from '../views/Leaderboard';
import Terms from '../views/Terms';
import Faq from '../views/Faq';
import Fair from '../views/fair/Fair';
import FairOverview from '../views/fair/FairOverview';
import FairBlackjack from '../views/fair/FairBlackjack';
import FairMines from '../views/fair/FairMines';
import FairTowers from '../views/fair/FairTowers';
import FairUpgrader from '../views/fair/FairUpgrader';
import FairRoulette from '../views/fair/FairRoulette';
import FairCases from '../views/fair/FairCases';
import FairBattles from '../views/fair/FairBattles';

const Admin = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/Admin');
const AdminDashboard = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminDashboard');
const AdminUsers = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminUsers');
const AdminUser = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminUser');
const AdminTransactions = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminTransactions');
const AdminBoxes = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminBoxes');
const AdminBoxesCreate = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminBoxesCreate');
const AdminPromo = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminPromo');
const AdminPromoCreate = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminPromoCreate');
const AdminCodes = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminCodes');
const AdminCodesCreate = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminCodesCreate');
const AdminLeaderboards = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminLeaderboards');
const AdminLeaderboardsCreate = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminLeaderboardsCreate');
const AdminReports = () => import(/* webpackChunkName: "group-admin" */ '../views/admin/AdminReports');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter(to, from, next) {
            if(to.query.token !== undefined) {
                store.dispatch('authExchangeToken', { authToken: to.query.token, authType: to.query.type });
                next({ path: to.query.type === 'link' ? '/profile/settings' : to.path, query: {} });
            } else { next(); }
        }
    },
    {
        path: '/blackjack',
        name: 'Blackjack',
        component: Blackjack
    },
    {
        path: '/mines',
        name: 'Mines',
        component: Mines
    },
    {
        path: '/towers',
        name: 'Towers',
        component: Towers
    },
    {
        path: '/upgrader',
        name: 'Upgrader',
        component: Upgrader
    },
    {
        path: '/roulette',
        name: 'Roulette',
        component: Roulette
    },
    {
        path: '/cases',
        component: Cases,
        children: [
            {
                path: '',
                name: 'CasesOverview',
                component: CasesOverview
            },
            {
                path: ':boxId',
                name: 'CasesBox',
                component: CasesBox
            }
        ]
    },
    {
        path: '/battles',
        component: Battles,
        children: [
            {
                path: '',
                name: 'BattlesOverview',
                component: BattlesOverview
            },
            {
                path: 'create',
                name: 'BattlesCreate',
                component: BattlesCreate
            },
            {
                path: ':gameId',
                name: 'BattlesGame',
                component: BattlesGame
            }
        ]
    },
    {
        path: '/deposit',
        component: Deposit,
        meta: {
            auth: true
        },
        children: [
            {
                path: '',
                name: 'DepositRust',
                component: DepositRust
            },
            {
                path: 'crypto',
                name: 'DepositCrypto',
                component: DepositCrypto
            },
            {
                path: 'gift',
                name: 'DepositGift',
                component: DepositGift
            },
            {
                path: 'credit',
                name: 'DepositCredit',
                component: DepositCredit
            }
        ]
    },
    {
        path: '/withdraw',
        component: Withdraw,
        meta: {
            auth: true
        },
        children: [
            {
                path: '',
                name: 'WithdrawRust',
                component: WithdrawRust
            }
        ]
    },
    {
        path: '/profile',
        component: Profile,
        meta: {
            auth: true
        },
        children: [
            {
                path: '',
                name: 'ProfileOverview',
                component: ProfileOverview
            },
            {
                path: 'settings',
                name: 'ProfileSettings',
                component: ProfileSettings
            },
            {
                path: 'bets',
                name: 'ProfileBets',
                component: ProfileBets
            },
            {
                path: 'transactions',
                name: 'ProfileTransactions',
                component: ProfileTransactions
            }
        ]
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq
    },
    {
        path: '/fair',
        component: Fair,
        children: [
            {
                path: '',
                name: 'FairOverview',
                component: FairOverview
            },
            {
                path: 'blackjack',
                name: 'FairBlackjack',
                component: FairBlackjack
            },
            {
                path: 'mines',
                name: 'FairMines',
                component: FairMines
            },
            {
                path: 'towers',
                name: 'FairTowers',
                component: FairTowers
            },
            {
                path: 'upgrader',
                name: 'FairUpgrader',
                component: FairUpgrader
            },
            {
                path: 'roulette',
                name: 'FairRoulette',
                component: FairRoulette
            },
            {
                path: 'cases',
                name: 'FairCases',
                component: FairCases
            },
            {
                path: 'battles',
                name: 'FairBattles',
                component: FairBattles
            }
        ]
    },
    {
        path: '/leaderboard',
        name: 'Leaderboard',
        component: Leaderboard
    },
    {
        path: '/admin',
        component: Admin,
        meta: {
            auth: true,
            admin: true
        },
        children: [
            {
                path: '',
                name: 'AdminDashboard',
                component: AdminDashboard
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component: AdminUsers
            },
            {
                path: 'users/:userId',
                name: 'AdminUser',
                component: AdminUser
            },
            {
                path: 'transactions',
                name: 'AdminTransactions',
                component: AdminTransactions
            },
            {
                path: 'boxes',
                name: 'AdminBoxes',
                component: AdminBoxes
            },
            {
                path: 'boxes/create',
                name: 'AdminBoxesCreate',
                component: AdminBoxesCreate
            },
            {
                path: 'promo',
                name: 'AdminPromo',
                component: AdminPromo
            },
            {
                path: 'promo/create',
                name: 'AdminPromoCreate',
                component: AdminPromoCreate
            },
            {
                path: 'codes',
                name: 'AdminCodes',
                component: AdminCodes
            },
            {
                path: 'codes/create',
                name: 'AdminCodesCreate',
                component: AdminCodesCreate
            },
            {
                path: 'leaderboards',
                name: 'AdminLeaderboards',
                component: AdminLeaderboards
            },
            {
                path: 'leaderboards/create',
                name: 'AdminLeaderboardsCreate',
                component: AdminLeaderboardsCreate
            },
            {
                path: 'reports',
                name: 'AdminReports',
                component: AdminReports
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async function(to, from, next) {
    if(store.getters.authToken.token !== null && store.getters.authUser.user === null && store.getters.authUser.loading === false) {
        await store.dispatch('authGetUser');

        if(store.getters.authUser.user !== null && store.getters.authUser.user.agreed !== true) {
            store.dispatch('modalsSetShow', 'Agree');
        }
    }

    const refCode = to.query.ref !== undefined ? to.query.ref : localStorage.getItem('ref-code') !== null ? localStorage.getItem('ref-code') : null;
    if(refCode !== null && store.getters.authUser.user !== null && store.getters.authUser.user.agreed !== undefined) {
        localStorage.removeItem('ref-code');
        store.dispatch('modalsSetData', { code: refCode });
        store.dispatch('modalsSetShow', 'Redeem');
    } else if(refCode !== null) {
        localStorage.setItem('ref-code', refCode);
    }

    if(to.matched.some(record => record.meta.auth) && store.getters.authUser.user === null) {
        router.push({ name: 'Home'});
    } else if(to.matched.some(record => record.meta.admin) && (store.getters.authUser.user === null || store.getters.authUser.user.rank !== 'admin')) {
        router.push({ name: 'Home'});
    } else {
        next();
    }
});

export default router;
