<template>
    <div class="modal-agree">
        <div class="agree-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="agree-content">
            <div class="content-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="106" height="95.001" viewBox="0 0 106 95.001">
                    <path d="M-1910.5-2262h-53l-26.5-47.5,26.5-47.5h53l26.5,47.5-26.5,47.5Zm-14.637-65.6h0l-4.2,4.2v9.975l2.624,2.624-2.624,2.625v9.976l4.2,4.2h12.075l4.2-4.2v-9.976l-2.625-2.625,2.625-2.624v-9.975l-4.2-4.2Zm-13.475,3.15V-2294h3.15v-33.6h-7.875v3.15Zm-22.662,18.375v8.924h3.15v-8.924h8.924v-3.151h-8.924v-8.925h-3.15v8.925h-8.925v3.151Zm46.637,8.924h-8.925l-2.624-2.625v-6.825l2.624-2.625h8.925l2.625,2.625v6.825l-2.625,2.624Zm0-15.224h-8.925l-2.624-2.625v-6.825l2.624-2.625h8.925l2.625,2.625V-2315l-2.625,2.625Z" transform="translate(1990 2357)" />
                </svg>
            </div>
            <div class="content-check">
                <input v-model="modalAgree" type="checkbox" />
                <div class="check-text">I am 18+ and have read and agree to the <a href="/terms" target="_blank">Terms of Service.</a></div>
            </div>
            <div class="content-button">
                <button v-on:click="modalAgreeButton()" class="button-okay" v-bind:disabled="modalAgree === false">
                    <span v-if="modalAgree === true">OKAY</span>
                    <span v-else>PLEASE AGREE TO THE TERMS</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'ModalAgree',
        data() {
            return {
                modalAgree: false
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'modalsSetData',
                'userSendSettingSocket'
            ]),
            modalAgreeButton() {
                this.userSendSettingSocket({ setting: 'agreed', value: true });
                
                this.modalsSetShow(null);

                const refCode = localStorage.getItem('ref-code');
                if(refCode !== null) {
                    setTimeout(() => {
                        this.modalsSetData({ code: refCode });
                        this.modalsSetShow('Redeem');
                    }, 200);
                }
            }
        }
    }
</script>

<style scoped>
    .modal-agree {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0 44px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-agree .agree-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-agree .agree-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-agree .agree-close button:hover {
        color: #ffffff;
    }

    .modal-agree .agree-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-agree .agree-close button:hover svg {
        fill: #ffffff;
    }

    .modal-agree .agree-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-agree .content-icon {

    }

    .modal-agree .content-icon svg {
        fill: #fd3b31;
    }

    .modal-agree .content-check {
        display: flex;
        align-items: center;
        margin-top: 23px;
    }

    .modal-agree .content-check input {
        width: 20px;
        height: 20px;
        margin-right: 9px;
        cursor: pointer;
    }

    .modal-agree .check-text {
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-agree .check-text a {
        text-decoration: underline;
        color: #fd3b31;
    }

    .modal-agree .content-button {
        width: 370px;
        margin-top: 18px;
    }

    .modal-agree .content-button button.button-okay {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-agree .content-button button.button-okay:disabled {
        opacity: 0.5;
    }

    .modal-agree .content-button button.button-okay:not([disabled]):hover {
        background: #00de56;
    }

    @media only screen and (max-width: 650px) {

        .modal-agree {
            width: 100%;
        }

        .modal-agree .agree-content {
            padding: 0 20px;
        }

        .modal-agree .content-button {
            width: 100%;
        }

    }
</style>
