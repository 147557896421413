<template>
    <div class="blackjack">
        <div class="blackjack-container">
            <div class="container-actions">
                <div class="actions-controls">
                    <div class="controls-input">
                        <div class="input-title">PLACE YOUR BET</div>
                        <div class="input-content">
                            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                            <input v-model="blackjackAmount" v-on:input="blackjackAmount = generalFormatInputAmount(blackjackAmount)" v-bind:disabled="blackjackIsGameRunning === true" type="text" />
                            <div class="content-buttons">
                                <button v-on:click="blackjackSetAmount('1/2')" v-bind:disabled="blackjackIsGameRunning === true">1/2</button>
                                <button v-on:click="blackjackSetAmount('x2')" v-bind:disabled="blackjackIsGameRunning === true">x2</button>
                                <button v-on:click="blackjackSetAmount('max')" v-bind:disabled="blackjackIsGameRunning === true">MAX</button>
                            </div>
                        </div>
                    </div>
                    <div class="controls-decision">
                        <div class="decision-title">MAKE YOUR DECISION</div>
                        <div class="decision-content">
                            <transition name="fade" mode="out-in">
                                <div 
                                    class="content-insurance" 
                                    v-if="
                                        blackjackData.game !== null && 
                                        blackjackData.game.actions.length === 1 && 
                                        blackjackData.game.dealerStack.cards.length > 1 && 
                                        blackjackData.game.dealerStack.cards[0].rank === 'A' && 
                                        blackjackData.game.playerStack.cards.length > 1 && 
                                        blackjackGetCardValue(blackjackData.game.playerStack.cards) !== 21
                                    " 
                                    key="insurance"
                                >
                                    <button v-on:click="blackjackActionButton('insurance', true)" class="button-insurance">
                                        INSURANCE
                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M-0.000122354 6.5C-0.000122198 10.0899 2.90998 13 6.49988 13C10.0898 13 12.9999 10.0899 12.9999 6.5C12.9999 2.9101 10.0898 1.46631e-06 6.49988 1.62322e-06C2.90998 1.78014e-06 -0.000122511 2.9101 -0.000122354 6.5ZM7.06391 4.53763L9.27478 6.74857C9.42735 6.90117 9.50848 7.1053 9.50848 7.3128C9.50848 7.41563 9.48868 7.5191 9.44781 7.61787C9.32434 7.91587 9.03345 8.11033 8.71094 8.11033L4.28884 8.11033C3.96631 8.11033 3.67541 7.91587 3.55191 7.61787C3.42854 7.31987 3.49678 6.97687 3.72494 6.74857L5.93588 4.53763C6.24734 4.22617 6.75241 4.22617 7.06391 4.53763Z" />
                                        </svg>
                                    </button>
                                    <button v-on:click="blackjackActionButton('insurance', false)" class="button-noinsurance">
                                        NO INSURANCE
                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000121786 6.5C0.000121943 2.9101 2.91022 1.27205e-07 6.50012 2.84124e-07C10.09 4.41044e-07 13.0001 2.9101 13.0001 6.5C13.0001 10.0899 10.09 13 6.50012 13C2.91022 13 0.000121629 10.0899 0.000121786 6.5ZM7.06416 8.46237L9.27502 6.25143C9.42759 6.09883 9.50872 5.8947 9.50872 5.6872C9.50872 5.58437 9.48892 5.4809 9.44806 5.38213C9.32459 5.08413 9.03369 4.88967 8.71119 4.88967L4.28909 4.88967C3.96656 4.88967 3.67566 5.08413 3.55216 5.38213C3.42879 5.68013 3.49702 6.02313 3.72519 6.25143L5.93612 8.46237C6.24759 8.77383 6.75266 8.77383 7.06416 8.46237Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="content-normal" v-else key="normal">
                                    <button v-on:click="blackjackActionButton('hit')" class="button-hit" v-bind:disabled="socketSendLoading !== null || blackjackIsGameRunning === false">
                                        HIT
                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M-0.000122354 6.5C-0.000122198 10.0899 2.90998 13 6.49988 13C10.0898 13 12.9999 10.0899 12.9999 6.5C12.9999 2.9101 10.0898 1.46631e-06 6.49988 1.62322e-06C2.90998 1.78014e-06 -0.000122511 2.9101 -0.000122354 6.5ZM7.06391 4.53763L9.27478 6.74857C9.42735 6.90117 9.50848 7.1053 9.50848 7.3128C9.50848 7.41563 9.48868 7.5191 9.44781 7.61787C9.32434 7.91587 9.03345 8.11033 8.71094 8.11033L4.28884 8.11033C3.96631 8.11033 3.67541 7.91587 3.55191 7.61787C3.42854 7.31987 3.49678 6.97687 3.72494 6.74857L5.93588 4.53763C6.24734 4.22617 6.75241 4.22617 7.06391 4.53763Z" />
                                        </svg>
                                    </button>
                                    <button v-on:click="blackjackActionButton('stand')" class="button-stand" v-bind:disabled="socketSendLoading !== null || blackjackIsGameRunning === false">
                                        STAND
                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000121786 6.5C0.000121943 2.9101 2.91022 1.27205e-07 6.50012 2.84124e-07C10.09 4.41044e-07 13.0001 2.9101 13.0001 6.5C13.0001 10.0899 10.09 13 6.50012 13C2.91022 13 0.000121629 10.0899 0.000121786 6.5ZM7.06416 8.46237L9.27502 6.25143C9.42759 6.09883 9.50872 5.8947 9.50872 5.6872C9.50872 5.58437 9.48892 5.4809 9.44806 5.38213C9.32459 5.08413 9.03369 4.88967 8.71119 4.88967L4.28909 4.88967C3.96656 4.88967 3.67566 5.08413 3.55216 5.38213C3.42879 5.68013 3.49702 6.02313 3.72519 6.25143L5.93612 8.46237C6.24759 8.77383 6.75266 8.77383 7.06416 8.46237Z" />
                                        </svg>
                                    </button>
                                    <button v-on:click="blackjackActionButton('split')" class="button-split" v-bind:disabled="
                                        socketSendLoading !== null ||
                                        blackjackIsGameRunning === false ||
                                        blackjackData.game.actions.includes('split') === true ||
                                        blackjackData.game.playerStack.cards.length !== 2 ||
                                        blackjackData.game.playerStack.cards[0].rank !== blackjackData.game.playerStack.cards[1].rank
                                    ">
                                        SPLIT
                                        <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.900208 8.82508H14.1002C14.5972 8.82508 15.0001 8.42226 15.0001 7.92499C15.0001 7.42784 14.5973 7.0249 14.1002 7.0249H0.900208C0.403061 7.02503 0.00012207 7.42796 0.00012207 7.92499C0.00012207 8.42214 0.403061 8.82508 0.900208 8.82508Z" />
                                            <path d="M7.4754 4.77526C8.70232 4.77526 9.70031 3.77727 9.70031 2.55023C9.70031 1.32331 8.70232 0.325195 7.4754 0.325195C6.2486 0.325195 5.25024 1.32331 5.25024 2.55023C5.25012 3.77715 6.24836 4.77526 7.4754 4.77526Z" />
                                            <path d="M9.7003 12.45C9.7003 11.2231 8.70232 10.2251 7.47527 10.2251C6.2486 10.2251 5.25024 11.2231 5.25024 12.45C5.25024 13.6767 6.24823 14.6749 7.47527 14.6749C8.70219 14.6749 9.7003 13.6772 9.7003 12.45Z" />
                                        </svg>
                                    </button>
                                    <button v-on:click="blackjackActionButton('double')" class="button-double" v-bind:disabled="
                                        socketSendLoading !== null ||
                                        blackjackIsGameRunning === false ||
                                        blackjackData.game.actions.includes('split') === true ||
                                        blackjackData.game.playerStack.cards.length !== 2 ||
                                        blackjackGetCardValue(blackjackData.game.playerStack.cards) < 9 ||
                                        blackjackGetCardValue(blackjackData.game.playerStack.cards) > 11
                                    ">
                                        DOUBLE
                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.50054 2.63831L11.1354 7.27313C11.5619 7.69964 12.2536 7.69965 12.6801 7.27313C13.1066 6.84662 13.1066 6.15488 12.6801 5.72836L7.27238 0.320618C6.84587 -0.105897 6.15413 -0.105897 5.72762 0.320618L0.319882 5.72836C-0.106611 6.15488 -0.106611 6.84662 0.319882 7.27313C0.746396 7.69964 1.43813 7.69964 1.86465 7.27313L6.50054 2.63831ZM6.50651 9.16429L10.0323 12.6771C10.4647 13.1085 11.1667 13.1085 11.5992 12.6771C12.0317 12.2457 12.0317 11.5469 11.5992 11.1155L7.28971 6.82225C6.85725 6.39085 6.5406 6.46014 6.11409 6.88666L1.41324 11.1155C0.980778 11.5469 0.980778 12.2457 1.41324 12.6771C1.8457 13.1085 2.54772 13.1085 2.9802 12.6771L6.50651 9.16429Z" />
                                        </svg>
                                    </button>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="controls-bet">
                        <button 
                            v-on:click="blackjackCreateButton()" 
                            class="button-create" 
                            v-bind:disabled="socketSendLoading !== null || blackjackIsGameRunning === true || blackjackDealing === true"
                        >PLACE BET</button>
                    </div>
                </div>
                <div class="actions-info">
                    <router-link to="/fair/blackjack" class="link-fair">
                        <IconFairness />
                        FAIRNESS
                    </router-link>
                    <button v-on:click="blackjackMuteButton()" class="button-sound">
                        <IconMute v-if="soundBlackjack === 1" />
                        <IconUnmute v-else />
                        {{ soundBlackjack === 1 ? 'MUTE' : 'UNMUTE' }}

                    </button>
                </div>
            </div>

            <BlackjackGame />
        </div>

        <Bets />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import mixins from '@/mixins';
    import IconFairness from '@/components/icons/IconFairness';
    import IconMute from '@/components/icons/IconMute';
    import IconUnmute from '@/components/icons/IconUnmute';
    import BlackjackGame from '@/components/blackjack/BlackjackGame';
    import Bets from '@/components/bets/Bets';

    export default {
        name: 'Blackjack',
        metaInfo: {
            title: 'Blackjack - RustyRocket.GG'
        },
        components: {
            IconFairness,
            IconMute,
            IconUnmute,
            BlackjackGame,
            Bets
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                blackjackAmount: '0.00'
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'blackjackGetDataSocket',
                'blackjackSendCreateSocket', 
                'blackjackSendInsuranceSocket', 
                'blackjackSendHitSocket', 
                'blackjackSendStandSocket', 
                'blackjackSendSplitSocket', 
                'blackjackSendDoubleSocket', 
                'soundSetBlackjack'
            ]),
            blackjackSetAmount(action) {
                let amount = Math.floor(this.towersAmount * 1000);

                if(action === '1/2') {
                    amount = Math.floor(amount / 2);
                } else if(action === 'x2') {
                    amount = Math.floor(amount * 2);
                } else if(action === 'max') {
                    amount = this.authUser.user.balance;
                }

                amount = amount >= this.authUser.user.balance ? this.authUser.user.balance : amount;
                this.blackjackAmount = (Math.floor(amount / 10) / 100).toFixed(2);
            },
            blackjackCreateButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(isNaN(this.blackjackAmount) === true || this.blackjackAmount < 0.1) {
                    this.notificationShow({type: 'error', message: 'Your provided bet amount is invalid.'});
                    return;
                }

                this.blackjackSendCreateSocket({ amount: Math.floor(this.blackjackAmount * 1000) });
            },
            blackjackActionButton(action, insurance) {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(action === 'insurance') { this.blackjackSendInsuranceSocket({ insurance: insurance }); }
                else if(action === 'hit') { this.blackjackSendHitSocket({}); }
                else if(action === 'stand') { this.blackjackSendStandSocket({}); }
                else if(action === 'split') { this.blackjackSendSplitSocket({}); }
                else if(action === 'double') { this.blackjackSendDoubleSocket({}); }
            },
            blackjackMuteButton() {
                if(this.soundBlackjack === 1) {
                    this.soundSetBlackjack(0);
                } else {
                    this.soundSetBlackjack(1);
                }
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser', 
                'blackjackDealing', 
                'blackjackData', 
                'soundBlackjack'
            ]),
            blackjackIsGameRunning() {
                return this.blackjackData.game !== null && this.blackjackData.game.state !== 'completed';
            }
        },
        created() {
            if(this.authUser.user !== null) {this.blackjackGetDataSocket({}); }
        }
    }
</script>

<style scoped>
    .blackjack {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 70px 0;
    }

    .blackjack .blackjack-container {
        width: 100%;
        display: flex;
    }

    .blackjack .container-actions {
        width: 380px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .blackjack .actions-controls {
        width: 100%;
    }

    .blackjack .controls-input {
        width: 100%;
    }

    .blackjack .input-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .blackjack .input-content {
        width: 100%;
        position: relative;
        margin-top: 12px;
        border-radius: 8px;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .blackjack .input-content img {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .blackjack .input-content input {
        width: 100%;
        height: 58px;
        padding: 0 160px 0 54px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .blackjack .content-buttons {
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
    }

    .blackjack .content-buttons button {
        margin: 0 15px;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .blackjack .content-buttons button:not([disabled]):hover {
        color: #ffffff;
    }

    .blackjack .controls-decision {
        width: 100%;
        margin-top: 24px;
    }

    .blackjack .decision-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .blackjack .decision-content {
        width: 100%;
        margin-top: 12px;
    }

    .blackjack .content-insurance {
        width: 100%;
    }

    .blackjack .content-insurance.fade-enter-active,
    .blackjack .content-insurance.fade-leave-active {
        transition: opacity 0.5s;
    }

    .blackjack .content-insurance.fade-enter-from,
    .blackjack .content-insurance.fade-leave-to {
        opacity: 0;
    }

    .blackjack .content-insurance button {
        width: 100%;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .blackjack .content-insurance button.button-insurance {
        margin-bottom: 16px;
    }

    .blackjack .content-insurance button.button-insurance svg {
        margin-left: 6px;
        fill: #00c74d;
    }

    .blackjack .content-insurance button.button-noinsurance svg {
        margin-left: 6px;
        fill: #fd3b31;
    }

    .blackjack .content-normal {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .blackjack .content-normal.fade-enter-active,
    .blackjack .content-normal.fade-leave-active {
        transition: opacity 0.5s;
    }

    .blackjack .content-normal.fade-enter-from,
    .blackjack .content-normal.fade-leave-to {
        opacity: 0;
    }

    .blackjack .content-normal button {
        width: calc(50% - 8px);
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .blackjack .content-normal button:nth-child(odd) {
        margin-right: 16px;
    }

    .blackjack .content-normal button.button-hit,
    .blackjack .content-normal button.button-stand {
        margin-bottom: 16px;
    }

    .blackjack .content-normal button.button-hit svg {
        margin-left: 6px;
        fill: #00c74d;
    }

    .blackjack .content-normal button.button-stand svg {
        margin-left: 6px;
        fill: #fd3b31;
    }

    .blackjack .content-normal button.button-split svg {
        margin-left: 6px;
        fill: #67c4f8;
    }

    .blackjack .content-normal button.button-double svg {
        margin-left: 6px;
        fill: #ffc93d;
    }

    .blackjack .controls-bet {
        width: 100%;
        margin-top: 32px;
    }

    .blackjack .controls-bet button.button-create {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .blackjack .controls-bet button.button-create:not([disabled]):hover {
        background: #00de56;
    }

    .blackjack .actions-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }

    .blackjack .actions-info a.link-fair,
    .blackjack .actions-info button.button-sound {
        width: calc(50% - 8px);
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .blackjack .actions-info a.link-fair:hover,
    .blackjack .actions-info button.button-sound:hover {
        color: #ffffff;
    }

    .blackjack .actions-info a.link-fair svg,
    .blackjack .actions-info button.button-sound svg {
        width: 16px;
        margin-right: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .blackjack .actions-info a.link-fair:hover svg,
    .blackjack .actions-info button.button-sound:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1330px) {

        .blackjack {
            width: 100%;
            padding: 20px 10px;
        }

    }

    @media only screen and (max-width: 1100px) {

        .blackjack .blackjack-container {
            flex-direction: column-reverse;
        }

        .blackjack .container-actions {
            width: 100%;
            height: auto;
            margin-top: 15px;
        }

    }
</style>
