import router from '../../../router';

const state = {
    battlesFilterSortCases: 'highest',
    battlesFilterSearch: '',
    battlesFilterPrice: 'ALL',
    battlesFilterMode: '1v1',
    battlesSelected: [],
    battlesData: {
        games: [],
        history: [],
        loading: false
    },
    battlesBoxesData: {
        boxes: [],
        loading: false
    },
    battlesGameData: {
        game: null,
        loading: false
    }
}

const getters = {
    battlesFilterSortCases: state => state.battlesFilterSortCases,
    battlesFilterSearch: state => state.battlesFilterSearch,
    battlesFilterPrice: state => state.battlesFilterPrice,
    battlesFilterMode: state => state.battlesFilterMode,
    battlesSelected: state => state.battlesSelected,
    battlesData: state => state.battlesData,
    battlesBoxesData: state => state.battlesBoxesData,
    battlesGameData: state => state.battlesGameData
}

const mutations = {
    battles_set_filter_sort_cases(state, value) {
        state.battlesFilterSortCases = value;
    },
    battles_set_filter_search(state, value) {
        state.battlesFilterSearch = value;
    },
    battles_set_filter_price(state, value) {
        state.battlesFilterPrice = value;
    },
    battles_set_filter_mode(state, value) {
        state.battlesFilterMode = value;
    },
    battles_set_boxes(state, boxes) {
        state.battlesBoxes = boxes;
    },
    battles_add_selected(state, box) {
        state.battlesSelected.push(box);
    },
    battles_remove_selected(state, index) {
        state.battlesSelected.splice(index, 1);
    },
    battles_empty_selected(state) {
        state.battlesSelected = [];
    },
    battles_set_data_games(state, games) {
        state.battlesData.games = games;
    },
    battles_add_data_games(state, game) {
        state.battlesData.games.push(game);
    },
    battles_update_data_games(state, game) {
        state.battlesData.games.splice(state.battlesData.games.findIndex((element) => element._id === game._id), 1, game);
    },
    battles_remove_data_games(state, game) {
        state.battlesData.games.splice(state.battlesData.games.findIndex((element) => element._id === game._id), 1);
    },
    battles_set_data_history(state, history) {
        state.battlesData.history = history;
    },
    battles_add_data_history(state, game) {
        state.battlesData.history.unshift(game);
    },
    battles_remove_last_data_history(state) {
        state.battlesData.history.pop();
    },
    battles_set_data_loading(state, status) {
        state.battlesData.loading = status;
    },
    battles_set_boxes_data_boxes(state, boxes) {
        state.battlesBoxesData.boxes = boxes;
    },
    battles_set_boxes_data_loading(state, status) {
        state.battlesBoxesData.loading = status;
    },
    battles_set_game_data_game(state, game) {
        state.battlesGameData.game = game;
    },
    battles_set_game_data_loading(state, status) {
        state.battlesGameData.loading = status;
    }
}

const actions = {
    battlesSetFilterSortCases({ commit }, value) {
        commit('battles_set_filter_sort_cases', value);
    },
    battlesSetFilterSearch({ commit }, value) {
        commit('battles_set_filter_search', value);
    },
    battlesSetFilterPrice({ commit }, value) {
        commit('battles_set_filter_price', value);
    },
    battlesSetFilterMode({ commit }, value) {
        commit('battles_set_filter_mode', value);
    },
    battlesResetFilter({ commit }) {
        commit('battles_set_filter_search', '');
        commit('battles_set_filter_mode', '1v1');
        commit('battles_empty_selected');
    },
    battlesAddSelected({ commit }, item) {
        commit('battles_add_selected', item);
    },
    battlesRemoveSelected({ getters, commit }, box) {
        const index = getters.battlesSelected.findIndex((element) => element._id === box._id);
        if(index !== -1) { commit('battles_remove_selected', index); }
    },
    battlesEmptySelected({ commit }) {
        commit('battles_empty_selected');
    },
    battlesSetGameData({ commit }, game) {
        commit('battles_set_game_data_game', game);
    },
    battlesSocketGame({ getters, commit }, data) {
        if(data.game.options.private === false) {
            if(data.game.state !== 'completed') {
                if(getters.battlesData.games.some((element) => element._id === data.game._id) === true) { commit('battles_update_data_games', data.game); }
                else { commit('battles_add_data_games', data.game); }
            } else {
                commit('battles_remove_data_games', data.game);
                commit('battles_add_data_history', data.game);
                if(getters.battlesData.history.length > 4) { commit('battles_remove_last_data_history'); }
            }
        }

        if(getters.battlesGameData.game !== null && getters.battlesGameData.game._id === data.game._id) { commit('battles_set_game_data_game', { ...data.game, boxes: getters.battlesGameData.game.boxes }); }
    },
    battlesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.battlesData.loading === true) { return; }
        commit('battles_set_data_loading', true);

        getters.socketClient.emit('battles:getData', data, (res) => {
            if(res.success === true) {
                commit('battles_set_data_games', res.games);
                commit('battles_set_data_history', res.history);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('battles_set_data_loading', false);
        });
    },
    battlesGetBoxesDataSocket({ getters, commit, dispatch }, data) {
        if(getters.battlesBoxesData.loading === true) { return; }
        commit('battles_set_boxes_data_loading', true);

        getters.socketClient.emit('battles:getBoxesData', data, (res) => {
            if(res.success === true) {
                commit('battles_set_boxes_data_boxes', res.boxes);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('battles_set_boxes_data_loading', false);
        });
    },
    battlesGetGameDataSocket({ getters, commit, dispatch }, data) {
        if(getters.battlesGameData.loading === true) { return; }
        commit('battles_set_game_data_loading', true);

        getters.socketClient.emit('battles:getGameData', data, (res) => {
            if(res.success === true) {
                commit('battles_set_game_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('battles_set_game_data_loading', false);
        });
    },
    battlesSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BattlesCreate');

        getters.socketClient.emit('battles:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                router.push({ path: '/battles/' + res.game._id });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    battlesSendBotSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BattlesBot');

        getters.socketClient.emit('battles:sendBot', data, (res) => {
            if(res.success === true) {
                
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    battlesSendJoinSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BattlesJoin');

        getters.socketClient.emit('battles:sendJoin', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                router.push({ path: '/battles/' + data.gameId });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const battles = {
    state,
    mutations,
    actions,
    getters
}

export default battles;