<template>
    <div class="battles-box-element" v-bind:class="{ 'element-selected': battlesSelectedCount >= 1 }">
        <div class="element-image">
            <img v-bind:src="box.image" />
        </div>
        <div class="element-name">{{box.name}}</div>
        <div v-if="battlesSelectedCount === 0" class="element-select">
            <div class="select-amount">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="amount-value">
                    <span>{{ generalFormatAmount(box.amount).split('.')[0] }}</span>.{{ generalFormatAmount(box.amount).split('.')[1] }}
                </div>
            </div>
            <button v-on:click="battlesAddButton()" class="button-add">ADD CASE</button>
        </div>
        <div v-else class="element-count">
            <button v-on:click="battlesDecreaseButton()" class="button-decrease">
                <svg width="7" height="2" viewBox="0 0 7 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2V0H7V2H0Z" />
                </svg>
            </button>
            {{ battlesSelectedCount }}
            <button v-on:click="battlesIncreaseButton()" class="button-increase">
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0572 2.94779H8V5.1004H5.0572V8H2.9428V5.1004H0V2.94779H2.9428V0H5.0572V2.94779Z" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BattlesBoxElement',
        mixins: [
            mixins
        ],
        props: [
            'box'
        ],
        methods: {
            ...mapActions([
                'battlesAddSelected',
                'battlesRemoveSelected',
                'battlesEmptySelected'
            ]),
            battlesAddButton() {
                this.battlesAddSelected(this.box);
            },
            battlesIncreaseButton() {
                this.battlesAddSelected(this.box);
            },
            battlesDecreaseButton() {
                this.battlesRemoveSelected(this.box);
            }
        },
        computed: {
            ...mapGetters([
                'battlesSelected'
            ]),
            battlesSelectedCount() {
                return this.battlesSelected.filter((element) => element._id === this.box._id).length;
            }
        }
    }
</script>

<style scoped>
    .battles-box-element {
        width: calc(20% - 6.4px);
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 8px;        
        padding: 24px 16px 16px 16px;
        border-radius: 8px;
        background: #12161d;
    }

    .battles-box-element:nth-child(5n) {
        margin-right: 0; 
    }

    .battles-box-element .element-image {
        display: flex;
        justify-content: center;
    }

    .battles-box-element .element-image img {
        width: 135px;
        transition: transform 0.3s ease;
    }

    .battles-box-element:hover .element-image img {
        transform: scale(1.06);
    }

    .battles-box-element .element-name {
        margin-top: 18px;
        font-size: 15px;
        font-weight: 600;
        color: #767c8b;
    }

    .battles-box-element .element-select {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        border-radius: 5px;
        background: #212732;
    }

    .battles-box-element .select-amount {
        height: 28px;
        display: flex;
        align-items: center;
    }

    .battles-box-element:hover .select-amount {
        display: none;
    }

    .battles-box-element .select-amount img {
        width: 21px;
        margin-right: 10px;
    }

    .battles-box-element .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .battles-box-element .amount-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .battles-box-element .element-select button.button-add {
        width: 100%;
        height: 100%;
        display: none;
        font-size: 13px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-box-element:hover .element-select button.button-add {
        display: block;
    }

    .battles-box-element .element-count {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        border-radius: 5px;
        background: #212732;
    }

    .battles-box-element .element-count button.button-decrease,
    .battles-box-element .element-count button.button-increase {
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.3s ease;
    }

    .battles-box-element .element-count button.button-decrease svg,
    .battles-box-element .element-count button.button-increase svg {
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .battles-box-element .element-count button.button-decrease:hover svg,
    .battles-box-element .element-count button.button-increase:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 950px) {

        .battles-box-element {
            width: calc(25% - 6px);       
        }

        .battles-box-element:nth-child(5n) {
            margin-right: 8px; 
        }

        .battles-box-element:nth-child(4n) {
            margin-right: 0; 
        }

    }

    @media only screen and (max-width: 750px) {

        .battles-box-element {
            width: calc(33.33% - 5.33px);       
        }

        .battles-box-element:nth-child(4n) {
            margin-right: 8px; 
        }

        .battles-box-element:nth-child(3n) {
            margin-right: 0; 
        }

    }

    @media only screen and (max-width: 600px) {

        .battles-box-element {
            width: calc(50% - 4px);       
        }

        .battles-box-element:nth-child(3n) {
            margin-right: 8px; 
        }

        .battles-box-element:nth-child(2n) {
            margin-right: 0; 
        }

    }
</style>